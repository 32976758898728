<template>
    <div>
        <v-tooltip bottom location="auto" opacity="1">
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="icon-tooltip-icon-area"
                    :class="iconClass"
                    :color="color"
                    v-bind="attrs"
                    v-on="on"
                    :icon="icon"
                >
                    {{ icon }}
                </v-icon>
            </template>
            <span v-if="!largeTooltip" class="icon-tooltip-container tooltip-multiline">
                {{  tooltipText }}
            </span>

            <span v-else>
                <div class="large-tooltip-container">
                    <div class="large-tooltip-title">
                        <v-icon :class="largeTooltipIconClass" color="#ffffff">{{ largeTooltipIcon }}</v-icon> {{ largeTooltipTitle }}
                    </div>
                    <div class="large-tooltip-content">
                        <slot>

                        </slot>
                    </div>
                </div>
            </span>
        </v-tooltip>
    </div>    
</template>

<script>
    export default {

        name: 'IconTooltip',

        props: {
        
            icon: {
                type: String,
                required: true
            },
            iconClass: {
                type: String,
                required: false
            },
            color: {
                type: String,
                default: 'primary'
            },
            tooltipText: {
                type: String,
                required: false
            },
            largeTooltip: {
                type: Boolean,
                default: false
            },
            largeTooltipTitle: {
                type: String,
                required: false
            },
            largeTooltipIcon: {
                type: String,
                required: false
            },
            largeTooltipIconClass: {
                type: String,
                required: false
            }
        },


    }
</script>

<style scoped lang="scss">
@import './src/design/components/tooltip.scss';

.icon-tooltip-icon-area {
    cursor: help;
    font-size: $font-size-sm;
}
</style>