var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", location: "auto", opacity: "1" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "icon-tooltip-icon-area",
                          class: _vm.iconClass,
                          attrs: { color: _vm.color, icon: _vm.icon },
                        },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          !_vm.largeTooltip
            ? _c(
                "span",
                { staticClass: "icon-tooltip-container tooltip-multiline" },
                [_vm._v(" " + _vm._s(_vm.tooltipText) + " ")]
              )
            : _c("span", [
                _c("div", { staticClass: "large-tooltip-container" }, [
                  _c(
                    "div",
                    { staticClass: "large-tooltip-title" },
                    [
                      _c(
                        "v-icon",
                        {
                          class: _vm.largeTooltipIconClass,
                          attrs: { color: "#ffffff" },
                        },
                        [_vm._v(_vm._s(_vm.largeTooltipIcon))]
                      ),
                      _vm._v(" " + _vm._s(_vm.largeTooltipTitle) + " "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "large-tooltip-content" },
                    [_vm._t("default")],
                    2
                  ),
                ]),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }